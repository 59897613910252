import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-img">
          <img src={require("../img/golden-eagle-logo.png").default} alt="" />
        </div>
        <div className="footer-copyright">
          <h5>&copy; 2021, Golden Eagle</h5>
        </div>
      </div>
    </div>
  );
};

export default Footer;
