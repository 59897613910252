import React from "react";
import Detail from "../ui/Detail";
import { Link } from "react-router-dom";
const Soap = () => {
  return (
    <div className="product-detail-container">
      <div className="detail-content">
        <div className="image">
          <img
            src={require("../img/radhas-soap.jpeg").default}
            alt=""
            srcset=""
          />
        </div>
        <div className="detail-text">
          <h3>Radhas Soaps</h3>

          <p>
            <span> Radhas Ayurvedic soap</span>
            <br />
            In the process of making ayurvedic soaps, antioxidant properties are
            preserved in its ingredients. The presence of antioxidants helps the
            skin to rejuvenate itself and leaves the skin looking young and
            fresh. Since ayurvedic soaps are gentle, they do not affect the pH
            balance of the skin, and makes it less prone to irritation. <br />
            Radhas Ayurvedic soaps with decades of experience in soap
            manufacturing have manufactured the best in industry ayurvedic soap
            which Reduces skin inflammation Radhas Ayurvedic soaps consist of
            natural antibacterial agents such as neem, turmeric, sandalwood,
            peppermint, tea tree oil, etc. Thus, Radhas ayurvedic soaps help to
            keep the skin germ-free and prevent the occurrence of acne and
            pimples.
            <br />
            Adequate moisturisation from Radhas ayurvedic soap keeps the skin
            free from ailments such as dermatitis and ensure a healthy glow. As
            part of International trades Radhas Soap manufacturers made special
            agreements with GOLDEN EAGLE IMPORTS SDN.BHD.
            <br />
            <span> Radhas Sandal Soaps :</span>
            <br />
            Sandalwood is a natural exfoliant that helps to remove dirt,
            pollutants, and congested pores from the skin. It softens and glows
            the skin by brightening the complexion, lightening fine wrinkles,
            and rejuvenating skin cells. The distinctive soft, smooth, and
            creamy aroma of sandalwood imparts a similar texture to the soap,
            making the skin smooth, soft, and supple.
            <br />
            The main ingredient of Radhas Sandal soap is unadulterated
            sandalwood oil. It's suitable for normal, healthy skin because it's
            free of harsh synthetic chemicals. It additionally helps to enhance
            the look and acts as a germicide. Radhas Sandal soap is fantastic
            for all skin types and leaves a long-lasting sandalwood smell.
            <br />
            <span>Arya Herbal soaps</span>
            <br />
            Herbal soaps, which are made from rare herbs and 100 percent natural
            components, have been proved to be quite helpful to the skin. The
            medicinal and restorative properties of the herbs utilised in these
            soaps provide special advantages to the skin, such as sustenance,
            strength, healing, and moisturising. These soaps also include ultra
            fatty oils, Vitamin E, aloe vera, and essential oils, all of which
            contribute to skin health.
            <br />
            Arya Herbal soap is a herbal handcrafted bathing bar that manages
            excess oil secretion and skin without drying it out. It hydrates and
            moisturises the skin, making it soft and supple. It also protects
            against acne-causing bacteria and promotes healthy skin.
            <br />
          </p>
          <div className="detail-btn">
            <a href="https://shopee.com.my/goldeneagleimports?smtt=0.0.9" className="btn detail">
              Buy Now{" "}
            </a>
          </div>
        </div>
      </div>
      <div className="gallery">
        <div className="gallery-head">Here are some of our Products</div>
        <div className="gallery-items">
          <div className="gallery-item">
            <Detail img="Radhas-Ayurvedic-Soap.jpeg" />
          </div>
          <div className="gallery-item">
            <Detail img="Radhas-sandal-soap.jpeg" />
          </div>
          <div className="gallery-item">
            <Detail img="ARYA-Herbal-Soap.jpeg" />
          </div>

        </div>
      </div>
    </div>
  );
};

export default Soap;
