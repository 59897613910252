import "./App.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import TopNav from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Product from "./pages/Product";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import ProductDetail from "./pages/ProductDetail";
import Soap from "./pages/Soap";
import ChetProducts from "./pages/ChetProducts";

function App() {
  return (
    <BrowserRouter>
      <TopNav />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/products" component={Product} />
        <Route path="/detail" component={ProductDetail} />
        <Route path="/soap" component={Soap} />
        <Route path="/chetproducts" component={ChetProducts} />
        <Route path="/contact" component={Contact} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
