// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import React from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
const Slider = () => {
    return (
        <Carousel className="slider-section" variant="dark">
            <Carousel.Item interval={3000} className="slider-container">
                <Carousel.Caption>
                    <div className="header-btn">
                        <Link to="/about" className="btn main-btn">
                            Find out More
                        </Link>
                    </div>
                </Carousel.Caption>
                <img
                    className="slider-img"
                    src={require("../img/newslide1.jpeg").default}
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item interval={3000} className="slider-container">
                <img
                    className="slider-img"
                    src={require("../img/newslide2.jpeg").default}
                    alt="Second slide"
                />

                <Carousel.Caption>
                    <div className="header-btn">
                        <Link to="/about" className="btn main-btn">
                            Find out More
                        </Link>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={3000} className="slider-container">
                <img
                    className="slider-img"
                    src={require("../img/newslide3.jpeg").default}
                    alt="third slide"
                />

                <Carousel.Caption>
                    <div className="header-btn">
                        <Link to="/about" className="btn main-btn">
                            Find out More
                        </Link>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={3000} className="slider-container">
                <img
                    className="slider-img"
                    src={require("../img/newslide4.jpeg").default}
                    alt="Fourth slide"
                />

                <Carousel.Caption>
                    <div className="header-btn">
                        <Link to="/about" className="btn main-btn">
                            Find out More
                        </Link>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={3000} className="slider-container">
                <img
                    className="slider-img"
                    src={require("../img/newslide5.jpg").default}
                    alt="Fourth slide"
                />

                <Carousel.Caption>
                    <div className="header-btn">
                        <Link to="/about" className="btn main-btn">
                            Find out More
                        </Link>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
};

export default Slider;
