import React from "react";
import { Link } from "react-router-dom";
import Slider from "../ui/Slider";

const Header = () => {
  return (
    <div className="header">
      <div className="header-content">
        <div className="header-text">
          <h3>
            Our mission is simple –<br />
            <span>
              {" "}
              to import many good global brands all over the globe and to supply
              for the needs of our location.
            </span>
          </h3>
          <div className="header-btn">
            <Link to="/about" className="btn main-btn">
              Find out More
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
