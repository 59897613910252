import React from "react";
import Banner from "../ui/Banner";

const About = () => {
  return (
    <div>
      <div className="banner">
        <div className="banner-content  about-banner">
          <h4 className="banner-head">About Us</h4>
        </div>
      </div>
      <div className="about-container">
        <div className="about-content">
          <div className="about-text">
            <p>
              GOLDEN EAGLE IMPORTS SDN BHD founded in 2019 by Dileep Krish & the key people includes financial partner Saravanan Pillai,  Our Company
              was set up specifically to help and support local shops and
              business dealing with daily needs all over Malaysia. We have more
              than three decades of hands-on experience of worldwide
              international trade so we are able to provide solutions to the
              multitude of challenges encountered in import activities.
              <br />
              The Company has built a business on a strong, long-standing
              foundation that has earned our clients' trust and reliance in
              terms of high-quality products and great service.<br />
              Our best
              products and services have been consistently maintained through
              ongoing trainings and the intensive development of skills and
              knowledge provided to our personnel in order to improve the pre-
              and post-quality standard products and services for the utmost
              satisfaction of every client who is relevant to the Company's
              policies. The Company now offers a wider range of products and
              services in and around Malaysia.
            </p>
          </div>
          <div className="about-image">
            <img src={require("../img/product - alaukik-catalogue-01.jpg").default} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
