import React from "react";
import { Link } from "react-router-dom";
import Detail from "../ui/Detail";
const ChetProducts = () => {
  return (
    <div className="product-detail-container">
      <div className="detail-content">
        <div className="image">
          <img
            src={require("../img/logo.jpg").default}
            alt=""
            srcset=""
          />
        </div>
        <div className="detail-text">
          <h3> Chettinadu Products</h3>

          <p>
            Chettinadu Masala is a well-known brand that aims to reinvent the flavour of non-vegetarian cuisine, particularly chettinad dishes.
            We are one of the most well-known brands in India, Malaysia, and Southeast Asia, as well as other chettinadu Traditional Non-Vegetarian Lovers throughout the world.
            <br />
            Despite the fact that Chettinadu Masala began as a small-scale business, it has grown its product market by employing a clever strategy of packaging its products in varied quantities of 50gm, 100gm, 200gm and 1kg in order to reach clients of all socio-economic classes.
            <br />

            For the best preparation of our products, we utilise a first-class quality mixture of ground spices and organic ingredients to make your cuisine tasty.
            <br />

            The objective of Chettinadu Masala is to provide natural flavour to our customers' foods while cooking with our finest spices and masalas so that they may enjoy their dinner.
            Our product has developed a specialty in non-vegetarian cuisine, which is always in high demand among our customers.
            <br />

            Our products includes :
            <br />

            *Chettinadu Biryani Rice Masala
            <br />

            Chettinadu Masalas
            <br />

            * Chicken
            <br />

            * Mutton
            <br />

            * Fish
            <br />

            * Chicken 65
            <br />

            * Sambar Masala Powder
            <br />




          </p>
          <div className="detail-btn">
            <a href="https://shopee.com.my/goldeneagleimports?smtt=0.0.9" className="btn detail">
              Buy Now{" "}
            </a>
          </div>
        </div>
      </div>
      <div className="gallery">
        <div className="gallery-head">Here are some of our Products</div>
        <div className="gallery-items">
          <div className="gallery-item">
            <Detail img="masala-1.jpeg" />
          </div>
          <div className="gallery-item">
            <Detail img="masala02.jpeg" />
          </div>
          <div className="gallery-item">
            <Detail img="masala-3.jpeg" />
          </div>
          <div className="gallery-item">
            <Detail img="masala-4.jpeg" />
          </div>
          <div className="gallery-item">
            <Detail img="masala-6.jpg" />
          </div>
          <div className="gallery-item">
            <Detail img="masala-7.jpg" />
          </div>

        </div>
      </div>
    </div>
  );
};

export default ChetProducts;
