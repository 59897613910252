import React from "react";
import Detail from "../ui/Detail";
const ProductDetail = () => {
  return (
    <div className="product-detail-container">
      <div className="detail-content">
        <div className="image">
          <img
            src={require("../img/product - alaukik-catalogue-23.jpeg").default}
            alt=""
            srcset=""
          />
        </div>
        <div className="detail-text">
          <h3>Alaukik Incense sticks</h3>

          <p>
            We all have a different way of life. Reflecting this diversity,
            Alaukik, have developed an assorted bouquet of fragrances to suit
            your need and touch your soul. Fancy Fragrance Natural Fragrance
            Floral Fragrance Refreshing Fragrance Religious Fragrance
          </p>
          <div className="detail-btn">
            <a href="https://shopee.com.my/goldeneagleimports?smtt=0.0.9" className="btn detail">
              Buy Now{" "}
            </a>
          </div>
        </div>
      </div>
      <div className="gallery">
        <div className="gallery-head">Here are some of our Products</div>
        <div className="gallery-items">
          <div className="gallery-item">
            <Detail img="product - alaukik-catalogue-02.jpg" />
          </div>
          <div className="gallery-item">
            <Detail img="product - alaukik-catalogue-03.jpg" />
          </div>
          <div className="gallery-item">
            <Detail img="product - alaukik-catalogue-04.jpg" />
          </div>
          <div className="gallery-item">
            <Detail img="product - alaukik-catalogue-05.jpg" />
          </div>
          <div className="gallery-item">
            <Detail img="product - alaukik-catalogue-06.jpg" />
          </div>
          <div className="gallery-item">
            <Detail img="product - alaukik-catalogue-07.jpg" />
          </div>
          <div className="gallery-item">
            <Detail img="product - alaukik-catalogue-08.jpg" />
          </div>
          <div className="gallery-item">
            <Detail img="product - alaukik-catalogue-09.jpg" />
          </div>
          <div className="gallery-item">
            <Detail img="product - alaukik-catalogue-10.jpg" />
          </div>
          <div className="gallery-item">
            <Detail img="product - alaukik-catalogue-11.jpg" />
          </div>
          <div className="gallery-item">
            <Detail img="product - alaukik-catalogue-12.jpg" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
