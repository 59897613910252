import React from "react";
import { Link } from "react-router-dom";

const Product = () => {
  return (
    <div>
      <div className="banner">
        <div className="banner-content product-banner-content">
          <h4 className="banner-head">Products</h4>
        </div>
      </div>
      <div className="product-container">
        <div className="product-content">

          <div className="product-card">
            <div className="product-image incense-sticks">
              <img src={require('../img/product - alaukik-catalogue-23.jpeg').default} alt="" />
            </div>
            <div className="product-details">
              <div className="product-text">
                <h4>Alaukik Incense sticks</h4>
                <p>We all have a different way of life.
                  Reflecting this diversity, Alaukik have developed an assorted
                  bouquet of fragrances to suit your need and touch your soul.
                  <br />
                  Fancy Fragrance<br />
                  Natural Fragrance<br />
                  Floral Fragrance<br />
                  Refreshing Fragrance<br />
                  Religious Fragrance.</p>
              </div>
              <div className="product-btn">
                <Link to='/detail' className="btn btn-shop">
                  View Product
                </Link>
              </div>
            </div>
          </div>
          <div className="product-card">
            <div className="product-image">
              <img src={require('../img/radhas-soap.jpeg').default} alt="" />
            </div>
            <div className="product-details">
              <div className="product-text">
                <h4>Radhas Soaps</h4>
                <p>
                  Radhas Ayurvedic soap
                  <br />
                  In the process of making ayurvedic soaps, antioxidant properties are preserved in its ingredients. The presence of antioxidants helps the skin to rejuvenate itself and leaves the skin looking young and fresh. Since ayurvedic soaps are gentle, they do not affect the pH balance of the skin, and makes it less prone to irritation.
                  <br />
                  Radhas Ayurvedic soaps with decades of experience in soap manufacturing have manufactured the best in industry ayurvedic soap which Reduces skin inflammation Radhas Ayurvedic soaps consist of natural antibacterial agents such as neem, turmeric, sandalwood, peppermint, tea tree oil, etc. Thus, Radhas ayurvedic soaps help to keep the skin germ-free and prevent the occurrence of acne and pimples.
                  <br />
                  Adequate moisturisation from Radhas ayurvedic soap keeps the skin free from ailments such as dermatitis and ensure a healthy glow. As part of International trades Radhas Soap manufacturers made special agreements with GOLDEN EAGLE IMPORTS SDN.BHD.</p>
              </div>
              <div className="product-btn">
                <Link to='/soap' className="btn btn-shop">
                  View Product
                </Link>
              </div>
            </div>
          </div>
          <div className="product-card">
            <div className="product-image">
              <img src={require('../img/product-img-2.jpeg').default} alt="" />
            </div>
            <div className="product-details">
              <div className="product-text">
                <h4>Chettinadu Products</h4>
                <p>Chetti nadu Masala is a well-known brand that aims to reinvent the flavour of non-vegetarian cuisine, particularly chettinad dishes.
                  We are one of the most well-known brands in India, Malaysia, and Southeast Asia, as well as other chettinadu Traditional Non-Vegetarian Lovers throughout the world.

                  <br />
                  Our products includes :
                  <br />
                  *Chettinadu Biryani Rice Masala
                  <br />
                  Chettinadu Masalas
                  <br />
                  * Chicken
                  <br />
                  * Mutton
                  <br />
                  * Fish
                  <br />
                  * Chicken 65
                  <br />
                  * Sambar Masala Powder.</p>
              </div>
              <div className="product-btn">
                <Link to='/chetproducts' className="btn btn-shop">
                  View Product
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Product;
