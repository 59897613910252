import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Slider from "../ui/Slider";
// import CommentSlider from "../components/CommentSlider";
const Home = () => {
  return (
    <div>
      <Header />
      <Slider />

      <div className="categories-section">
        <div className="section-title">Products</div>
        <div className="cat-section-content">
          <div className="cat-card">
            <div className="cat-card-content">
              <div className="cat-image ">
                <img className="stick" src={require("../img/home_page_replace.jpeg").default} alt="" />
              </div>
              <div className="cat-title">Alaukik Incense sticks</div>
              <Link to="/detail" className="btn btn-shop cat-btn">
                More
              </Link>
            </div>
          </div>
          <div className="cat-card">
            <div className="cat-card-content">
              <div className="cat-image">
                <img className="soap" src={require("../img/radhas-soap.jpeg").default} alt="" />
              </div>
              <div className="cat-title">Radhas Soaps</div>
              <Link to="/soap" className="btn btn-shop cat-btn">
                More
              </Link>
            </div>
          </div>
          <div className="cat-card">
            <div className="cat-card-content">
              <div className="cat-image">
                <img className="chettinadu" src={require("../img/home_page_replace chettinadu image.jpeg").default} alt="" />
              </div>
              <div className="cat-title">Chettinadu Products</div>
              <Link to="/chetproducts" className="btn btn-shop cat-btn">
                More
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <Slider /> */}
      <div className="comment-container">
        <div className="section-title">What our Customers Say?</div>
        <div className="comment-section-container">
          <div className="comment-card-container">
            <div className="comment-card">
              <div className="comment-card-container">
                <div className="comment-card-content">
                  {/* <div className="comment-image">
                    <img src={require("../img/review-3.jpg").default} alt="" />
                  </div> */}
                  <div className="comment-details">
                    <div className="comment-title">Variation: Sandal</div>
                    <div className="stars">
                      <i class="uil uil-star"></i>
                      <i class="uil uil-star"></i>
                      <i class="uil uil-star"></i>
                      <i class="uil uil-star"></i>
                      <i class="uil uil-star"></i>
                    </div>
                    <div className="comment-desc">
                      I’ve purchased these incense  many times from this seller. It’s always prompt delivery with secure packaging. This incense range is absolutely lovely.
                    </div>
                  </div>
                  <div className="comment-name">cavitacn</div>
                </div>
              </div>
            </div>
          </div>
          <div className="comment-card-container">
            <div className="comment-card">
              <div className="comment-card-container">
                <div className="comment-card-content">
                  {/* <div className="comment-image">
                    <img src={require("../img/review-3.jpg").default} alt="" />
                  </div> */}
                  <div className="comment-details">
                    <div className="comment-title">Variation: Maharaja</div>
                    <div className="stars">
                      <i class="uil uil-star"></i>
                      <i class="uil uil-star"></i>
                      <i class="uil uil-star"></i>
                      <i class="uil uil-star"></i>
                      <i class="uil uil-star"></i>
                    </div>
                    <div className="comment-desc">
                      Good product quality
                      Good value for money
                      Fast delivery
                      Excellent service by seller
                    </div>
                  </div>
                  <div className="comment-name">durgaselvarajoo</div>
                </div>
              </div>
            </div>
          </div>
          <div className="comment-card-container">
            <div className="comment-card">
              <div className="comment-card-container">
                <div className="comment-card-content">
                  {/* <div className="comment-image">
                    <img src={require("../img/review-3.jpg").default} alt="" />
                  </div> */}
                  <div className="comment-details">
                    <div className="comment-title">Variation: Dutch Rose</div>
                    <div className="stars">
                      <i class="uil uil-star"></i>
                      <i class="uil uil-star"></i>
                      <i class="uil uil-star"></i>
                      <i class="uil uil-star"></i>
                      <i class="uil uil-star"></i>
                    </div>
                    <div className="comment-desc">
                      Third time buying from this seller and as always prompt delivery with secure packaging. Love these incense.
                    </div>
                  </div>
                  <div className="comment-name">amala_2011</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="section-title">Contact Us</div>
      <div className="contact-section-container">
        <div className="contact-content">
          <div className="contact-desc">
            <span>Can't Decide What to do </span>
            <br />
            Have a Question? <br />
            Contact Us.
          </div>
          <div className="contact-btn">
            <Link to="/contact" className="btn btn-contact">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
