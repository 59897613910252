// import { useState } from "react"
import React, { useState } from "react";

const Detail = ({ img }) => {
  const [srcImg, setsrc] = useState('')
  const [isSrc, setisSrc] = useState(false)
  function Src(e) {
    setsrc(e.target.src)
    if (srcImg !== '') {
      setisSrc(true)
    } else {
      setisSrc(false)
    }
  }
  return (
    <>
      <img src={require(`../img/${img}`).default} alt="" onClick={(e) => Src(e)} />
      {/* <Modal src={srcImg} isSrc={isSrc} /> */}
    </>
  );
};

export default Detail;
