import React from "react";
// import emailjs from "emailjs-com";

const Contact = () => {
  // function sendEmail() {
  //   e.preventDefault();

  //   emailjs.sendForm;
  // }
  return (
    <div>
      <div className="banner">
        <div className="banner-content contact-banner">
          <h4 className="banner-head">Contact Us</h4>
        </div>
      </div>

      <div className="contact-container">
        <div className="contact-head">
          Have a Question? <br /> Ask us{" "}
        </div>
      </div>
      <div className="contact-content">
        <div className="contact-detail">
          <div className="contact-detail-card">
            <div className="detail-icon">
              <i class="uil uil-map-marker"></i>
            </div>
            <div className="contact-info">
              Lot No. 2A/8(B) Anzen Business Park, No 3-9, Jalan 4/37A,
              Kawasan Industri Taman Bukit Maluri, 52100 Kepong Kuala
              Lumpur.
            </div>
          </div>
          <div className="contact-detail-card">
            <div className="detail-icon">
              <i class="uil uil-phone"></i>
            </div>
            <div className="contact-info">
              +60 11-108 61982 <br />
              +60 12-389 5459 <br />
            </div>
          </div>
          <div className="contact-detail-card">
            <div className="detail-icon">
              <i class="uil uil-envelope"></i>
            </div>
            <div className="contact-info">
              goldeneagleimportssb@gmail.com <br /> info@goldeneagleimports.com.my
            </div>
          </div>
        </div>
        <form className="contact-form" action="https://formsubmit.co/goldeneagleimportssb@gmail.com" method="POST">
          <input
            type="text"
            required
            className="contact-input"
            name="name"
            placeholder="Enter you Name"
          />
          <input
            type="email"
            name="email"
            required
            className="contact-input"
            placeholder="Enter Your Email"
          />
          <input
            type="text"
            name="_subject"
            required
            className="contact-input"
            placeholder="Enter Your Subject"
          />
          <input type="hidden" name="_captcha" value="false" />
          <textarea
            className="contact-input msg"
            name="message"
            required
            placeholder="Enter you Message"
            cols="30"
            rows="15"
          ></textarea>
          <button type="submit" className="btn btn-submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
