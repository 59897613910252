import React, { useState } from "react";
import { Link } from "react-router-dom";
const TopNav = () => {
  const [nav, setnav] = useState(false);
  return (
    <>
      <nav className="top-nav">
        <div className="info-bar">
          <div className="info-num">
            <>
              <span>Call Us Now:</span> +60 11-108 61982
            </>
          </div>
        </div>

        <div className="menu-bar">
          <div className="nav-logo">
            <Link to="/" className="logo-img">
              <img
                src={require("../img/golden-eagle-logo.png").default}
                alt=""
                className="logo"
              />
            </Link>
            <div className="menu-icon" onClick={() => setnav(!nav)}>
              <div className="icon">
                <i
                  className={nav === true ? "uil uil-times" : "uil uil-bars"}
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div className={nav === true ? "menu-list top" : "menu-list "}>
          <div className="menu-items">
            <Link to="/" className="menu-item">
              Home
            </Link>
            <Link to="/about" className="menu-item">
              About Us
            </Link>
            <Link to="/products" className="dropdown menu-item">
              Products <i className="uil uil-arrow-down"></i>
              <div className="dropdown-menu">
                <Link to="/detail" className="menu-item">
                  Alaukik Incense sticks
                </Link>
                <Link to="/soap" className="menu-item">
                  Radhas Soaps
                </Link>
                <Link to="/chetproducts" className="menu-item">
                  Chettinadu Products
                </Link>
              </div>
            </Link>
            <Link to="/contact" className="menu-item">
              Contact
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default TopNav;
